<template>
    <router-link :to="to" :class="link_class">
        <div class="item-wrapper">
            <font-awesome-icon :icon="icon"/>
            <b-badge class="overlay-badge" variant="danger" v-if="badge">{{ badge }}</b-badge>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "MobileFooterLink",
        props: {
            to: {
                type: Object,
                required: true
            },
            icon: {
                required: true
            },
            badge: {
                default: false
            }
        },
        computed: {
            link_class() {
                // On utilise le store et pas this.$route, sinon le composant ne se met pas à jour quand on change de page
                return this.$store.state.route.name === this.to.name ? ['footer-link', 'active'] : ['footer-link'];
            }
        }
    }
</script>

<style scoped>
    .footer-link {
        color: #525f7f;
    }

    .footer-link.active {
        color: #e20000;
    }

    .item-wrapper {
        position: relative;
        display: inline-block;
    }

    .overlay-badge {
        position: absolute;
        right: -.5rem;
        top: 0;
        background: #f0b967;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        color: white;
        padding: 3px 5px;
        font-size: 12px;
    }
</style>